
export const CREATE_PATIENT_API = {
  url: `/patients`,
  method: 'post'
};

export const INDEX_PATIENTS_API = {
  url: `/patients`,
  method: 'get',
};

export const FETCH_PATIENT_API = {
  url: `/patients/:id`,
  method: 'get',
};

export const UPDATE_PATIENT_API = {
  url: `/patients/:id`,
  method: 'put'
};

export const DELETE_PATIENT_API = {
  url: `/patients/:id`,
  method: 'delete'
};
