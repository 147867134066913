import { combineReducers } from 'redux';
import lineAttachmentReducers from "redux/model/lineAttachments/lineAttachment/lineAttachmentReducers";
import lineAttachmentsReducers from "redux/model/lineAttachments/lineAttachments/lineAttachmentsReducers";
import receivedNoticeReducers from "redux/model/receivedNotices/receivedNotice/receivedNoticeReducers";
import receivedNoticesReducers from "redux/model/receivedNotices/receivedNotices/receivedNoticesReducers";
import patientReducers from "redux/model/patients/patient/patientReducers";
import patientsReducers from "redux/model/patients/patients/patientsReducers";
import productGiftCardReducers from "redux/model/productGiftCards/productGiftCard/productGiftCardReducers";
import productGiftCardsReducers from "redux/model/productGiftCards/productGiftCards/productGiftCardsReducers";
import giftCardReducers from "redux/model/giftCards/giftCard/giftCardReducers";
import giftCardsReducers from "redux/model/giftCards/giftCards/giftCardsReducers";


export default combineReducers({
  lineAttachment: lineAttachmentReducers,
  lineAttachments: lineAttachmentsReducers,
  receivedNotice: receivedNoticeReducers,
  receivedNotices: receivedNoticesReducers,
  patient: patientReducers,
  patients: patientsReducers,
  productGiftCard: productGiftCardReducers,
  productGiftCards: productGiftCardsReducers,
  giftCard: giftCardReducers,
  giftCards: giftCardsReducers,
});
