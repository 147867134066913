import { combineReducers } from 'redux';
import formLineAttachmentReducers from "redux/ui/form/lineAttachment/formLineAttachmentReducers";
import formReceivedNoticeReducers from "redux/ui/form/receivedNotice/formReceivedNoticeReducers";
import formPatientReducers from "redux/ui/form/patient/formPatientReducers";
import formProductGiftCardReducers from "redux/ui/form/productGiftCard/formProductGiftCardReducers";
import formGiftCardReducers from "redux/ui/form/giftCard/formGiftCardReducers";

export default combineReducers({
  lineAttachment: formLineAttachmentReducers,
  receivedNotice: formReceivedNoticeReducers,
  patient: formPatientReducers,
  productGiftCard: formProductGiftCardReducers,
  giftCard: formGiftCardReducers,
});
