// @flow

import { combineReducers } from 'redux';

import layout from 'redux/layout/reducers';
import appMenu from 'redux/appMenu/reducers';
import auth from 'redux/auth/authReducers';
import ui from 'redux/ui/uiReducers';
import model from 'redux/model/modelReducers';

export default combineReducers({
    auth,
    appMenu,
    layout,
    ui,
    model,
});
