import { all } from 'redux-saga/effects';
import uiToastSaga from "redux/ui/toast/toastSaga";

function* uiSaga() {
  yield all([
    uiToastSaga(),
  ]);
}

export default uiSaga;
