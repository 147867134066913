export const INIT_PATIENT = 'INIT_PATIENT';

export const FETCH_PATIENT = 'FETCH_PATIENT';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILED = 'FETCH_PATIENT_FAILED';

export const CREATE_PATIENT = 'CREATE_PATIENT';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAILED = 'CREATE_PATIENT_FAILED';

export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILED = 'UPDATE_PATIENT_FAILED';

export const DELETE_PATIENT = 'DELETE_PATIENT';
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_FAILED = 'DELETE_PATIENT_FAILED';

export const ASSIGN_PATIENT = 'ASSIGN_PATIENT';
