// @flow

import { combineReducers } from 'redux';
import modalReducers from 'redux/ui/modal/modalReducers'
import imageEditorReducers from "redux/ui/imageEditor/imageEditorReducers";
import formReducers from "redux/ui/form/formReducers";

export default combineReducers({
  modal: modalReducers,
  form: formReducers,
  imageEditor: imageEditorReducers,
});
