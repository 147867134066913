import {
  INIT_PATIENTS,
  LIST_PATIENTS,
  LIST_PATIENTS_FAILED,
  LIST_PATIENTS_SUCCESS
} from "redux/model/patients/patients/constants";

export const initPatients = () => ({
  type: INIT_PATIENTS,
  payload: {}
});

export const listPatients = () => ({
  type: LIST_PATIENTS,
  payload: {}
});

export const listPatientsSuccess = ({ patients }) => ({
  type: LIST_PATIENTS_SUCCESS,
  payload: { patients }
});

export const listPatientsFailed = ({ error }) => ({
  type: LIST_PATIENTS_FAILED,
  payload: { error }
});
