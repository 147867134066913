import {
  CHECK_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  REFRESH_USER
} from "redux/auth/authConstants";
import { removeToken, setToken } from "api/api";
import { getLocalToken } from "helpers/authUtils";

export const manageAuthToken = store => next => action => {
  switch (action.type) {
    case CHECK_USER:
      setToken(getLocalToken());
      break;

    case LOGIN_USER_SUCCESS:
      setToken(action.payload.token);
      break;

    case REFRESH_USER:
      setToken(store.getState().auth.token);
      break;

    case LOGOUT_USER_SUCCESS:
      removeToken();
      break;

    default:
  }

  return next(action);
};
