import {
  CREATE_PATIENT_API,
  INDEX_PATIENTS_API,
  FETCH_PATIENT_API,
  DELETE_PATIENT_API,
  UPDATE_PATIENT_API
} from "api/patient/constants";
import { generateRoute } from "helpers/routesUtils";
import api from "api/api";

export const apiListPatients = () => {
  const { url, method } = INDEX_PATIENTS_API;

  return api({
    method,
    url,
  });
};

export const apiCreatePatient = ({ patient }) => {
  const { url, method } = CREATE_PATIENT_API;

  return api({
    method,
    url,
    data: patientParams({ patient }),
  });
};

export const apiFetchPatient = ({ patientId }) => {
  const { url, method } = FETCH_PATIENT_API;

  return api({
    method,
    url: generateRoute(url, patientId),
  });
};

export const apiUpdatePatient = ({ patient }) => {
  const { url, method } = UPDATE_PATIENT_API;

  return api({
    method,
    url: generateRoute(url, patient.id),
    data: patientParams({ patient }),
  });
};

export const apiDeletePatient = ({ patient }) => {
  const { url, method } = DELETE_PATIENT_API;

  return api({
    method,
    url: generateRoute(url, patient.id),
  });
};

const patientParams = ({ patient }) => {
  const {
    email,
    firstName,
    lastName,
    dateOfBirth,
    mobile,
    homePhone,
    gender,
    address,
    occupation,
    preferredLanguage,
    note,
    signature,
    privateHealthFundName,
    privateHealthFundMembershipNumber,
    privateHealthFundMembershipCode,
    emergencyContact,
    emergencyPhone,
  } = patient;

  return {
    patient: {
      email,
      firstName,
      lastName,
      dateOfBirth,
      mobile,
      homePhone,
      gender,
      address,
      occupation,
      preferredLanguage,
      note,
      signature,
      privateHealthFundName,
      privateHealthFundMembershipNumber,
      privateHealthFundMembershipCode,
      emergencyContact,
      emergencyPhone,
    }
  }
};
