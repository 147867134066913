import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_PATIENTS } from "redux/model/patients/patients/constants";
import { apiListPatients } from "api/patient/apiPatient";
import { listPatientsFailed, listPatientsSuccess } from "redux/model/patients/patients/patientsActions";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";


function* patientsSaga() {
  yield all([
    fork(watchListPatients),
  ]);
}

// List Patients
export function* watchListPatients() {
  yield takeEvery(LIST_PATIENTS, sagaListPatients);
}

export function* sagaListPatients() {
  try {
    const response = yield call(apiListPatients);

    const { patients } = response.data;

    yield put(listPatientsSuccess({ patients }));

  } catch (e) {
    yield put(listPatientsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default patientsSaga;
