import { all, call, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  CLEAR_ALL_RECEIVED_NOTICES,
  LIST_RECEIVED_NOTICES
} from "redux/model/receivedNotices/receivedNotices/constants";
import { apiClearAllReceivedNotices, apiListReceivedNotices } from "api/notification/receivedNotice/apiReceivedNotice";
import { listReceivedNoticesFailed, listReceivedNoticesSuccess } from "redux/model/receivedNotices/receivedNotices/receivedNoticesActions";
import { toastDefault, toastError } from "redux/ui/toast/toastActions";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";


function* receivedNoticesSaga() {
  yield all([
    fork(watchListReceivedNotices),
    fork(watchClearAllReceivedNotices),
  ]);
}

// List ReceivedNotices
export function* watchListReceivedNotices() {
  yield takeEvery(LIST_RECEIVED_NOTICES, sagaListReceivedNotices);
}

export function* sagaListReceivedNotices() {
  try {
    const response = yield call(apiListReceivedNotices);

    const { receivedNotices } = response.data;

    yield put(listReceivedNoticesSuccess({ receivedNotices }));

  } catch (e) {
    yield put(listReceivedNoticesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Clear All ReceivedNotices
export function* watchClearAllReceivedNotices() {
  yield takeLeading(CLEAR_ALL_RECEIVED_NOTICES, sagaClearAllReceivedNotices);
}

export function* sagaClearAllReceivedNotices() {
  try {
    const response = yield call(apiClearAllReceivedNotices);
    yield put(toastDefault({ message: 'All notifications have been marked as read.' }));

  } catch (e) {
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default receivedNoticesSaga;
