import {
  INIT_GIFT_CARDS,
  LIST_GIFT_CARDS,
  LIST_GIFT_CARDS_FAILED,
  LIST_GIFT_CARDS_SUCCESS
} from "redux/model/giftCards/giftCards/constants";

export const initGiftCards = () => ({
  type: INIT_GIFT_CARDS,
  payload: {}
});

export const listGiftCards = ({ page, query }) => ({
  type: LIST_GIFT_CARDS,
  payload: { page, query }
});

export const listGiftCardsSuccess = ({ giftCards, totalSize }) => ({
  type: LIST_GIFT_CARDS_SUCCESS,
  payload: { giftCards, totalSize }
});

export const listGiftCardsFailed = ({ error }) => ({
  type: LIST_GIFT_CARDS_FAILED,
  payload: { error }
});
