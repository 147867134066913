import React from "react";
import { BASE_PRODUCT_GIFT_CARD_PATH, INDEX_PRODUCT_GIFT_CARDS_PATH, NEW_PRODUCT_GIFT_CARD_PATH, SHOW_PRODUCT_GIFT_CARD_PATH } from "routes/productGiftCard/constants";
import PrivateRoute from "routes/PrivateRoute";

const ProductGiftCards = React.lazy(() => import('pages/productGiftCards/index/ProductGiftCards'));
const NewProductGiftCard = React.lazy(() => import('pages/productGiftCards/new/NewProductGiftCard'));
const ShowProductGiftCard = React.lazy(() => import('pages/productGiftCards/show/ShowProductGiftCard'));

export const productGiftCardRoutes = {
  path: BASE_PRODUCT_GIFT_CARD_PATH,
  name: 'ProductGiftCards',
  icon: 'far fa-star',
  children: [
    {
      path: INDEX_PRODUCT_GIFT_CARDS_PATH,
      exact: true,
      name: 'ProductGiftCards',
      component: ProductGiftCards,
      route: PrivateRoute,
    },
  ],
};

export const hiddenProductGiftCardRoutes = {
  children: [
    {
      path: SHOW_PRODUCT_GIFT_CARD_PATH,
      exact: true,
      name: 'Show ProductGiftCard',
      component: ShowProductGiftCard,
      route: PrivateRoute,
    }
  ]
}
