import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import reduxThunk from 'redux-thunk';
import { manageAuthToken } from "redux/middlewares/manageAuthToken";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, reduxThunk, manageAuthToken];

export function configureStore(initialState) {
    const composeEnhancers = generateCompose();

    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    sagaMiddleware.run(sagas, store.dispatch).toPromise().catch(e => {
        console.log({ message: e.message, source: 'sagaError', exception: e });
    });
    return store;
}

const generateCompose = () => {
    try {
        return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({name: window._env_.APP_DOMAIN}) || compose;
    } catch (e) {
        return compose
    }
};
