import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_GIFT_CARDS } from "redux/model/giftCards/giftCards/constants";
import { apiListGiftCards } from "api/giftCard/apiGiftCard";
import { listGiftCardsFailed, listGiftCardsSuccess } from "redux/model/giftCards/giftCards/giftCardsActions";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";


function* giftCardsSaga() {
  yield all([
    fork(watchListGiftCards),
  ]);
}

// List GiftCards
export function* watchListGiftCards() {
  yield takeEvery(LIST_GIFT_CARDS, sagaListGiftCards);
}

export function* sagaListGiftCards({ payload }) {
  try {
    const response = yield call(apiListGiftCards, payload);

    const { giftCards, totalSize } = response.data;

    yield put(listGiftCardsSuccess({ giftCards, totalSize }));

  } catch (e) {
    yield put(listGiftCardsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default giftCardsSaga;
