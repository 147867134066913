import { CHECK_USER_API, FETCH_CURRENT_STAFF_API, SIGN_IN_USER_API, SIGN_OUT_USER_API, } from "api/auth/constants";
import api from "api/api";

export const apiSignInUser = ({ username, password }) => {
  const { url, method } = SIGN_IN_USER_API;

  return api({
    method,
    url,
    data: {
      auth: {
        username: username,
        password: password,
      }
    }
  });
};

export const apiSignOutUser = () => {
  const { url, method } = SIGN_OUT_USER_API;

  return api({
    method,
    url,
  });
};

export const apiCheckUser = () => {
  const { url, method } = CHECK_USER_API;

  return api({
    method,
    url,
  });
};

export const apiFetchCurrentStaff = () => {
  const { url, method } = FETCH_CURRENT_STAFF_API;

  return api({
    method,
    url: url,
  });
};
