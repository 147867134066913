import { all } from 'redux-saga/effects';
import lineAttachmentSaga from "redux/model/lineAttachments/lineAttachment/lineAttachmentSaga";
import lineAttachmentsSaga from "redux/model/lineAttachments/lineAttachments/lineAttachmentsSaga";
import receivedNoticeSaga from "redux/model/receivedNotices/receivedNotice/receivedNoticeSaga";
import receivedNoticesSaga from "redux/model/receivedNotices/receivedNotices/receivedNoticesSaga";
import patientSaga from "redux/model/patients/patient/patientSaga";
import patientsSaga from "redux/model/patients/patients/patientsSaga";
import productGiftCardSaga from "redux/model/productGiftCards/productGiftCard/productGiftCardSaga";
import productGiftCardsSaga from "redux/model/productGiftCards/productGiftCards/productGiftCardsSaga";
import giftCardSaga from "redux/model/giftCards/giftCard/giftCardSaga";
import giftCardsSaga from "redux/model/giftCards/giftCards/giftCardsSaga";


function* modelSaga() {
  yield all([
    lineAttachmentSaga(),
    lineAttachmentsSaga(),
    receivedNoticeSaga(),
    receivedNoticesSaga(),
    patientSaga(),
    patientsSaga(),
    productGiftCardSaga(),
    productGiftCardsSaga(),
    giftCardSaga(),
    giftCardsSaga(),
  ]);
}

export default modelSaga;
