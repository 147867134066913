import {
  CREATE_PATIENT,
  CREATE_PATIENT_FAILED,
  CREATE_PATIENT_SUCCESS,
  DELETE_PATIENT, DELETE_PATIENT_FAILED, DELETE_PATIENT_SUCCESS,
  FETCH_PATIENT,
  FETCH_PATIENT_FAILED,
  FETCH_PATIENT_SUCCESS,
  INIT_PATIENT,
  UPDATE_PATIENT,
  UPDATE_PATIENT_FAILED,
  UPDATE_PATIENT_SUCCESS,
  ASSIGN_PATIENT
} from "redux/model/patients/patient/constants";

export const initPatient = () => ({
  type: INIT_PATIENT,
  payload: {}
});

export const createPatient = ({ patient }) => ({
  type: CREATE_PATIENT,
  payload: { patient }
});

export const createPatientSuccess = ({ patient }) => ({
  type: CREATE_PATIENT_SUCCESS,
  payload: { patient }
});

export const createPatientFailed = ({ error }) => ({
  type: CREATE_PATIENT_FAILED,
  payload: { error }
});

export const fetchPatient = ({ patientId }) => ({
  type: FETCH_PATIENT,
  payload: { patientId }
});

export const fetchPatientSuccess = ({ patient }) => ({
  type: FETCH_PATIENT_SUCCESS,
  payload: { patient }
});

export const fetchPatientFailed = ({ error }) => ({
  type: FETCH_PATIENT_FAILED,
  payload: { error }
});

export const updatePatient = ({ patient }) => ({
  type: UPDATE_PATIENT,
  payload: { patient }
});

export const updatePatientSuccess = ({ patient }) => ({
  type: UPDATE_PATIENT_SUCCESS,
  payload: { patient }
});

export const updatePatientFailed = ({ error }) => ({
  type: UPDATE_PATIENT_FAILED,
  payload: { error }
});

export const deletePatient = ({ patient }) => ({
  type: DELETE_PATIENT,
  payload: { patient }
});

export const deletePatientSuccess = ({ patient }) => ({
  type: DELETE_PATIENT_SUCCESS,
  payload: { patient }
});

export const deletePatientFailed = ({ error }) => ({
  type: DELETE_PATIENT_FAILED,
  payload: { error }
});

export const assignPatient = ({ fields }) => ({
  type: ASSIGN_PATIENT,
  payload: { fields }
});
