import React from "react";
import { BASE_PATIENT_PATH, INDEX_PATIENTS_PATH, NEW_PATIENT_PATH, SHOW_PATIENT_PATH } from "routes/patient/constants";
import PrivateRoute from "routes/PrivateRoute";

const Patients = React.lazy(() => import('pages/patients/index/Patients'));
const NewPatient = React.lazy(() => import('pages/patients/new/NewPatient'));
const ShowPatient = React.lazy(() => import('pages/patients/show/ShowPatient'));

export const patientRoutes = {
  path: BASE_PATIENT_PATH,
  name: 'Patients',
  icon: 'far fa-star',
  children: [
    {
      path: INDEX_PATIENTS_PATH,
      exact: true,
      name: 'Patients',
      component: Patients,
      route: PrivateRoute,
    },
    {
      path: NEW_PATIENT_PATH,
      exact: true,
      name: 'Add New',
      component: NewPatient,
      route: PrivateRoute,
    },
  ],
};

export const hiddenPatientRoutes = {
  children: [
    {
      path: SHOW_PATIENT_PATH,
      exact: true,
      name: 'Show Patient',
      component: ShowPatient,
      route: PrivateRoute,
    }
  ]
}
