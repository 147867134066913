export const SIGN_IN_USER_API = {
  url: `/auth/login`,
  method: 'post'
};

export const SIGN_OUT_USER_API = {
  url: `/auth/logout`,
  method: 'delete'
};

export const CHECK_USER_API = {
  url: `/auth/current`,
  method: 'get'
};

// Staff
export const FETCH_CURRENT_STAFF_API = {
  url: `/auth/current`,
  method: 'get',
};
