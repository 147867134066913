import { all } from 'redux-saga/effects';

import layoutSaga from 'redux/layout/saga';
import authSaga from 'redux/auth/authSaga';
import appMenuSaga from 'redux/appMenu/saga';
import uiSaga from "redux/ui/uiSaga";
import modelSaga from "redux/model/modelSaga";

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    layoutSaga(),
    appMenuSaga(),
    uiSaga(),
    modelSaga(),
  ]);
}
