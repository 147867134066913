import { TOAST_CYPRESS, TOAST_DEFAULT, TOAST_DELETED, TOAST_ERROR, TOAST_SAVED } from "redux/ui/uiConstants";

export const toastDefault = ({ message }) => ({
  type: TOAST_DEFAULT,
  payload: { message }
});

export const toastError = ({ message = 'Operation Failed.' }) => ({
  type: TOAST_ERROR,
  payload: { message }
});

export const toastSaved = ({ name }) => ({
  type: TOAST_SAVED,
  payload: { name }
});


export const toastDeleted = ({ name }) => ({
  type: TOAST_DELETED,
  payload: { name }
});

export const toastCypress = ({ message }) => ({
  type: TOAST_CYPRESS,
  payload: { message }
});
