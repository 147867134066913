import {
  ERROR_FORM_GIFT_CARD,
  LOADING_FORM_GIFT_CARD,
  RESET_FORM_GIFT_CARD
} from "redux/ui/form/giftCard/constants";
import {
  CREATE_GIFT_CARD,
  CREATE_GIFT_CARD_FAILED,
  CREATE_GIFT_CARD_SUCCESS,
  UPDATE_GIFT_CARD, UPDATE_GIFT_CARD_FAILED, UPDATE_GIFT_CARD_SUCCESS
} from "redux/model/giftCards/giftCard/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formGiftCardReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_GIFT_CARD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_GIFT_CARD:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_GIFT_CARD:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_GIFT_CARD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_GIFT_CARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_GIFT_CARD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_GIFT_CARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formGiftCardReducers;
