import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  CREATE_PATIENT,
  DELETE_PATIENT,
  FETCH_PATIENT,
  UPDATE_PATIENT
} from "redux/model/patients/patient/constants";
import {
  apiCreatePatient,
  apiDeletePatient,
  apiFetchPatient,
  apiUpdatePatient
} from "api/patient/apiPatient";
import {
  createPatientFailed,
  createPatientSuccess,
  deletePatientFailed,
  deletePatientSuccess,
  fetchPatientFailed,
  fetchPatientSuccess,
  initPatient,
  updatePatientFailed,
  updatePatientSuccess
} from "redux/model/patients/patient/patientActions";
import { toastDeleted, toastError, toastSaved } from "redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "redux/ui/modal/modalActions";
import { INDEX_PATIENTS_PATH } from "routes/patient/constants";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";
import { generateRoute } from "helpers/routesUtils";
import history from "routes/history";


function* patientSaga() {
  yield all([
    fork(watchFetchPatient),
    fork(watchUpdatePatient),
    fork(watchCreatePatient),
    fork(watchDeletePatient),
  ]);
}

// Fetch Patient
export function* watchFetchPatient() {
  yield takeEvery(FETCH_PATIENT, sagaFetchPatient);
}

export function* sagaFetchPatient({ payload }) {
  try {
    const response = yield call(apiFetchPatient, payload);

    const { patient } = response.data;
    yield put(fetchPatientSuccess({ patient }));

  } catch (e) {
    yield put(fetchPatientFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Patient
export function* watchCreatePatient() {
  yield takeLeading(CREATE_PATIENT, sagaCreatePatient);
}

export function* sagaCreatePatient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreatePatient, payload);

    const { patient } = response.data;
    yield put(createPatientSuccess({ patient }));
    yield put(toastSaved({ name: `Patient` }));


    history.push(generateRoute(INDEX_PATIENTS_PATH));
    yield put(initPatient());


  } catch (e) {
    yield put(createPatientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Patient
export function* watchUpdatePatient() {
  yield takeLeading(UPDATE_PATIENT, sagaUpdatePatient);
}

export function* sagaUpdatePatient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdatePatient, payload);

    const { patient } = response.data;
    yield put(updatePatientSuccess({ patient }));
    yield put(toastSaved({ name: 'Patient' }));
    history.push(generateRoute(INDEX_PATIENTS_PATH));
    yield put(initPatient());

  } catch (e) {
    yield put(updatePatientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Patient
export function* watchDeletePatient() {
  yield takeLeading(DELETE_PATIENT, sagaDeletePatient);
}

export function* sagaDeletePatient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeletePatient, payload);

    const { patient } = response.data;
    yield put(deletePatientSuccess({ patient }));
    yield put(toastDeleted({ name: 'Patient' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deletePatientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default patientSaga;
