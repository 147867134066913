import {
  CLOSE_FIRST_LAYER_MODAL, CLOSE_FOURTH_LAYER_MODAL,
  CLOSE_SECOND_LAYER_MODAL, CLOSE_THIRD_LAYER_MODAL,
  OPEN_FIRST_LAYER_MODAL, OPEN_FOURTH_LAYER_MODAL,
  OPEN_SECOND_LAYER_MODAL, OPEN_THIRD_LAYER_MODAL,
  START_FIRST_LAYER_MODAL_LISTENING, START_FOURTH_LAYER_MODAL_LISTENING,
  START_SECOND_LAYER_MODAL_LISTENING, START_THIRD_LAYER_MODAL_LISTENING,
  STOP_FIRST_LAYER_MODAL_LISTENING, STOP_FOURTH_LAYER_MODAL_LISTENING,
  STOP_SECOND_LAYER_MODAL_LISTENING, STOP_THIRD_LAYER_MODAL_LISTENING
} from "redux/ui/uiConstants";

type ModalAction = { type: string, payload: {} | string };

export const startFirstLayerModalListening = (): ModalAction => ({
  type: START_FIRST_LAYER_MODAL_LISTENING,
  payload: {},
});

export const stopFirstLayerModalListening = (): ModalAction => ({
  type: STOP_FIRST_LAYER_MODAL_LISTENING,
  payload: {},
});

export const openFirstLayerModal = (): ModalAction => ({
  type: OPEN_FIRST_LAYER_MODAL,
  payload: {},
});

export const closeFirstLayerModal = (): ModalAction => ({
  type: CLOSE_FIRST_LAYER_MODAL,
  payload: {},
});

export const openSecondLayerModal = (): ModalAction => ({
  type: OPEN_SECOND_LAYER_MODAL,
  payload: {},
});

export const closeSecondLayerModal = (): ModalAction => ({
  type: CLOSE_SECOND_LAYER_MODAL,
  payload: {},
});

export const startSecondLayerModalListening = (): ModalAction => ({
  type: START_SECOND_LAYER_MODAL_LISTENING,
  payload: {},
});

export const stopSecondLayerModalListening = (): ModalAction => ({
  type: STOP_SECOND_LAYER_MODAL_LISTENING,
  payload: {},
});

export const openThirdLayerModal = (): ModalAction => ({
  type: OPEN_THIRD_LAYER_MODAL,
  payload: {},
});

export const closeThirdLayerModal = (): ModalAction => ({
  type: CLOSE_THIRD_LAYER_MODAL,
  payload: {},
});

export const startThirdLayerModalListening = (): ModalAction => ({
  type: START_THIRD_LAYER_MODAL_LISTENING,
  payload: {},
});

export const stopThirdLayerModalListening = (): ModalAction => ({
  type: STOP_THIRD_LAYER_MODAL_LISTENING,
  payload: {},
});

export const openFourthLayerModal = (): ModalAction => ({
  type: OPEN_FOURTH_LAYER_MODAL,
  payload: {},
});

export const closeFourthLayerModal = (): ModalAction => ({
  type: CLOSE_FOURTH_LAYER_MODAL,
  payload: {},
});

export const startFourthLayerModalListening = (): ModalAction => ({
  type: START_FOURTH_LAYER_MODAL_LISTENING,
  payload: {},
});

export const stopFourthLayerModalListening = (): ModalAction => ({
  type: STOP_FOURTH_LAYER_MODAL_LISTENING,
  payload: {},
});
